<template>
  <div class="slides-main-div" v-if="model && model.item">
    <div class="first-slide heart">
      <img class="sansli-img" src="/images/luckyTable/header.png" />
      <h1 style=" font-size: 45px;color: #ae1c5d;text-transform: uppercase; font-family: 'Bungee-Regular';">
        ÇEKİLİŞİMİZ BAŞLIYOR
      </h1>
    </div>
    <div class="second-slide">
      <img class="number-img" src="/images/luckyTable/10.png" />
    </div>
    <div class="third-slide">
      <div class="slider-main-div">
        <img class="cangragulation-title" src="/images/luckyTable/tebrikler.png" />
        <div class="slider">
          <div class="wrap">
            <div v-for="table in model.item.selectedTables" :key="table.id" :id="table.id">
              {{ table.tableName }}
            </div>
            <!-- <div id="1">
              BAHCE MASA-01
            </div>
            <div id="2">
              BAHCE MASA-02
            </div>
            <div id="3">
              BAHCE MASA-03
            </div>
            <div id="4">
              BAHCE MASA-04
            </div>
            <div id="5">
              BAHCE MASA-05
            </div>
            <div id="6">
              MASA-06
            </div>
            <div id="7">
              MASA-07
            </div>
            <div id="8">
              MASA-08
            </div>
            <div id="9">
              BAHCE MASA-09
            </div>
            <div id="10">
              MASA-10
            </div> -->
          </div>
          <div class="prize-title">
            <h2>{{ model.item.prizeTitle }}</h2>
          </div>
        </div>

      </div>
    </div>
    <div class="firework-div">
      <div class="pyro">
        <div class="before"></div>
        <div class="after"></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import store from "../store/index";
export default {
  name: "lucky-table",
  props: ["data"],
  data() {
    return {
      destroyed: false,
      blinkTimer: null,
      blink_times: 5,
      blink_remained: 0,
      slideNumber: 10,
      circleNumber: 1,
      itemPassed: 0,
      speed: 100,
      itemCount: 1,
      randomN: 0
    };
  },
  created() {
    this.startTimer();
  },
  methods: {
    blink_text() {
      if (this.blink_remained-- > 0) {
        $(".cangragulation-title").fadeOut(250, () => {
          $(".cangragulation-title").fadeIn(250);
        });
      }
      else {
        if (this.blinkTimer) {
          clearInterval(this.blinkTimer);
        }
      }
    },
    randomIntFromInterval() { // min and max included 
      let coofitient =
      this.itemCount < 10 ? 4 :
      this.itemCount < 15 ? 4 :
      this.itemCount < 25 ? 3 :
      this.itemCount < 30 ? 3 :
      this.itemCount < 40 ? 2 :
      this.itemCount < 50 ? 2 :
      this.itemCount < 60 ? 2 : 2;
      let min = this.itemCount * (coofitient - 1), max = this.itemCount * coofitient;
      //let min = this.itemCount * 4, max = this.itemCount * 5;
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    init() {
      this.itemCount = this.model.item.selectedTables.length;
      this.randomN = this.randomIntFromInterval();
      this.slideOne();
    },
    slideOne() {
      $(".wrap")
        .find("div")
        .first()
        .animate(
          {
            height: "toggle"
          },
          this.speed,
          "linear",
          () => {
            this.appendEnd();
          }
        );
    },
    appendEnd() {
      if (++this.itemPassed % this.itemCount == 0)
        this.circleNumber++;
      this.speed = 10 * (this.circleNumber * 10);
      $(".wrap").find("div").first().appendTo(".wrap").show();
      if (this.itemPassed <= this.randomN)
        this.slideOne();
      else {
        $('.wrap div:not(#' + $(".wrap").find("div")[1].id + ')').css('visibility', 'hidden');
        $($(".wrap").find("div")[1]).animate(
          {
            'font-size': "80px",
            'padding-top': '35px'
          },
          500,
          "linear",
          () => {
            $('.firework-div').show(100);
            setTimeout(() => {
              $('.slider-title').addClass('slider-title-animate');
              $(".cangragulation-title").fadeIn(150, () => {
                this.blink_remained = this.blink_times;
                this.blinkTimer = setInterval(this.blink_text, 1000);
                $('.prize-title').fadeIn(500, () => {
                  setTimeout(() => {
                    store.dispatch("setNextPlaylist");
                  }, 30000);
                });
              });
            }, 500);
          }
        );
      }
    },
    startTimer() {
      setTimeout(() => {
        $('.first-slide').fadeOut(1, () => {
          $('.second-slide').fadeIn(250, () => {
            var counterTimer = setInterval(() => {
              this.slideNumber--;
              if (this.slideNumber <= 0) {
                clearInterval(counterTimer);
                $('.second-slide').fadeOut(200);
                $('.third-slide').fadeIn(500);
                this.init();
                return;
              }
              $('.number-img').animate(
                {
                  top: "-4000px"
                },
                500,
                "linear",
                () => {
                  $('.number-img').css({ 'display': 'none', 'top': '200%' });
                  $('.number-img').attr('src', '/images/luckyTable/' + this.slideNumber + '.png')
                  $('.number-img').css({ 'display': 'block' });
                  $('.number-img').animate(
                    {
                      top: "27%"
                    },
                    200,
                    "linear",
                    () => {
                    }
                  );
                }
              );
            }, 1000);
          });
        });
      }, 15000);
    }
  },
  computed: {
    model() {
      return this.$store.state.luckyTable;
    },
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>
<style lang="scss">
@font-face {
  font-family: 'Bungee-Regular';
  src: url(~@/assets/fonts/Bungee/Bungee-Regular.ttf);
}

.pyro>.before,
.pyro>.after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro>.after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: 136px -214.6666666667px #3300ff, 234px -93.6666666667px #00bbff, 20px -405.6666666667px #00ff44, -149px -282.6666666667px red, 79px 75.3333333333px #44ff00, 117px -177.6666666667px #0900ff, -90px -221.6666666667px #8000ff, 118px 67.3333333333px #003cff, -110px -390.6666666667px #22ff00, -77px 28.3333333333px #ff0066, 180px -193.6666666667px #00ff1a, 92px -94.6666666667px #9d00ff, 148px -89.6666666667px #00eeff, 157px -102.6666666667px #ff00bb, 139px -267.6666666667px red, -12px -245.6666666667px #ff0051, 17px -252.6666666667px #ff2f00, 82px -63.6666666667px #c800ff, 16px -372.6666666667px #2bff00, 44px -248.6666666667px #ff00ee, -175px 56.3333333333px #62ff00, 168px -151.6666666667px #ff5900, 238px -262.6666666667px #ddff00, 20px -257.6666666667px #0099ff, -89px -170.6666666667px #6a00ff, 195px -3.6666666667px #ff005e, -247px -226.6666666667px #0033ff, -169px 7.3333333333px #f7ff00, -150px -266.6666666667px #0091ff, 76px -230.6666666667px #ff0091, 13px -284.6666666667px #ff006a, 37px -95.6666666667px #0059ff, -92px -67.6666666667px #ff00b3, -212px -244.6666666667px #0077ff, 223px -20.6666666667px #ffa600, 42px -265.6666666667px #a600ff, 175px -367.6666666667px #6fff00, -158px -85.6666666667px #5900ff, 140px -167.6666666667px #ffc800, -30px -319.6666666667px #11ff00, -119px -223.6666666667px #ff00aa, -144px -142.6666666667px #ff9500, 250px 38.3333333333px #ff0004, 214px -334.6666666667px #ff0095, 73px -367.6666666667px #00ffd9, -41px 54.3333333333px #5500ff, -89px 78.3333333333px #4800ff, -25px -167.6666666667px #ff007b, -236px 23.3333333333px #84ff00, -217px -221.6666666667px #ff0009, 65px -288.6666666667px #00ff7b;
  }
}

@-moz-keyframes bang {
  to {
    box-shadow: 136px -214.6666666667px #3300ff, 234px -93.6666666667px #00bbff, 20px -405.6666666667px #00ff44, -149px -282.6666666667px red, 79px 75.3333333333px #44ff00, 117px -177.6666666667px #0900ff, -90px -221.6666666667px #8000ff, 118px 67.3333333333px #003cff, -110px -390.6666666667px #22ff00, -77px 28.3333333333px #ff0066, 180px -193.6666666667px #00ff1a, 92px -94.6666666667px #9d00ff, 148px -89.6666666667px #00eeff, 157px -102.6666666667px #ff00bb, 139px -267.6666666667px red, -12px -245.6666666667px #ff0051, 17px -252.6666666667px #ff2f00, 82px -63.6666666667px #c800ff, 16px -372.6666666667px #2bff00, 44px -248.6666666667px #ff00ee, -175px 56.3333333333px #62ff00, 168px -151.6666666667px #ff5900, 238px -262.6666666667px #ddff00, 20px -257.6666666667px #0099ff, -89px -170.6666666667px #6a00ff, 195px -3.6666666667px #ff005e, -247px -226.6666666667px #0033ff, -169px 7.3333333333px #f7ff00, -150px -266.6666666667px #0091ff, 76px -230.6666666667px #ff0091, 13px -284.6666666667px #ff006a, 37px -95.6666666667px #0059ff, -92px -67.6666666667px #ff00b3, -212px -244.6666666667px #0077ff, 223px -20.6666666667px #ffa600, 42px -265.6666666667px #a600ff, 175px -367.6666666667px #6fff00, -158px -85.6666666667px #5900ff, 140px -167.6666666667px #ffc800, -30px -319.6666666667px #11ff00, -119px -223.6666666667px #ff00aa, -144px -142.6666666667px #ff9500, 250px 38.3333333333px #ff0004, 214px -334.6666666667px #ff0095, 73px -367.6666666667px #00ffd9, -41px 54.3333333333px #5500ff, -89px 78.3333333333px #4800ff, -25px -167.6666666667px #ff007b, -236px 23.3333333333px #84ff00, -217px -221.6666666667px #ff0009, 65px -288.6666666667px #00ff7b;
  }
}

@-o-keyframes bang {
  to {
    box-shadow: 136px -214.6666666667px #3300ff, 234px -93.6666666667px #00bbff, 20px -405.6666666667px #00ff44, -149px -282.6666666667px red, 79px 75.3333333333px #44ff00, 117px -177.6666666667px #0900ff, -90px -221.6666666667px #8000ff, 118px 67.3333333333px #003cff, -110px -390.6666666667px #22ff00, -77px 28.3333333333px #ff0066, 180px -193.6666666667px #00ff1a, 92px -94.6666666667px #9d00ff, 148px -89.6666666667px #00eeff, 157px -102.6666666667px #ff00bb, 139px -267.6666666667px red, -12px -245.6666666667px #ff0051, 17px -252.6666666667px #ff2f00, 82px -63.6666666667px #c800ff, 16px -372.6666666667px #2bff00, 44px -248.6666666667px #ff00ee, -175px 56.3333333333px #62ff00, 168px -151.6666666667px #ff5900, 238px -262.6666666667px #ddff00, 20px -257.6666666667px #0099ff, -89px -170.6666666667px #6a00ff, 195px -3.6666666667px #ff005e, -247px -226.6666666667px #0033ff, -169px 7.3333333333px #f7ff00, -150px -266.6666666667px #0091ff, 76px -230.6666666667px #ff0091, 13px -284.6666666667px #ff006a, 37px -95.6666666667px #0059ff, -92px -67.6666666667px #ff00b3, -212px -244.6666666667px #0077ff, 223px -20.6666666667px #ffa600, 42px -265.6666666667px #a600ff, 175px -367.6666666667px #6fff00, -158px -85.6666666667px #5900ff, 140px -167.6666666667px #ffc800, -30px -319.6666666667px #11ff00, -119px -223.6666666667px #ff00aa, -144px -142.6666666667px #ff9500, 250px 38.3333333333px #ff0004, 214px -334.6666666667px #ff0095, 73px -367.6666666667px #00ffd9, -41px 54.3333333333px #5500ff, -89px 78.3333333333px #4800ff, -25px -167.6666666667px #ff007b, -236px 23.3333333333px #84ff00, -217px -221.6666666667px #ff0009, 65px -288.6666666667px #00ff7b;
  }
}

@-ms-keyframes bang {
  to {
    box-shadow: 136px -214.6666666667px #3300ff, 234px -93.6666666667px #00bbff, 20px -405.6666666667px #00ff44, -149px -282.6666666667px red, 79px 75.3333333333px #44ff00, 117px -177.6666666667px #0900ff, -90px -221.6666666667px #8000ff, 118px 67.3333333333px #003cff, -110px -390.6666666667px #22ff00, -77px 28.3333333333px #ff0066, 180px -193.6666666667px #00ff1a, 92px -94.6666666667px #9d00ff, 148px -89.6666666667px #00eeff, 157px -102.6666666667px #ff00bb, 139px -267.6666666667px red, -12px -245.6666666667px #ff0051, 17px -252.6666666667px #ff2f00, 82px -63.6666666667px #c800ff, 16px -372.6666666667px #2bff00, 44px -248.6666666667px #ff00ee, -175px 56.3333333333px #62ff00, 168px -151.6666666667px #ff5900, 238px -262.6666666667px #ddff00, 20px -257.6666666667px #0099ff, -89px -170.6666666667px #6a00ff, 195px -3.6666666667px #ff005e, -247px -226.6666666667px #0033ff, -169px 7.3333333333px #f7ff00, -150px -266.6666666667px #0091ff, 76px -230.6666666667px #ff0091, 13px -284.6666666667px #ff006a, 37px -95.6666666667px #0059ff, -92px -67.6666666667px #ff00b3, -212px -244.6666666667px #0077ff, 223px -20.6666666667px #ffa600, 42px -265.6666666667px #a600ff, 175px -367.6666666667px #6fff00, -158px -85.6666666667px #5900ff, 140px -167.6666666667px #ffc800, -30px -319.6666666667px #11ff00, -119px -223.6666666667px #ff00aa, -144px -142.6666666667px #ff9500, 250px 38.3333333333px #ff0004, 214px -334.6666666667px #ff0095, 73px -367.6666666667px #00ffd9, -41px 54.3333333333px #5500ff, -89px 78.3333333333px #4800ff, -25px -167.6666666667px #ff007b, -236px 23.3333333333px #84ff00, -217px -221.6666666667px #ff0009, 65px -288.6666666667px #00ff7b;
  }
}

@keyframes bang {
  to {
    box-shadow: 136px -214.6666666667px #3300ff, 234px -93.6666666667px #00bbff, 20px -405.6666666667px #00ff44, -149px -282.6666666667px red, 79px 75.3333333333px #44ff00, 117px -177.6666666667px #0900ff, -90px -221.6666666667px #8000ff, 118px 67.3333333333px #003cff, -110px -390.6666666667px #22ff00, -77px 28.3333333333px #ff0066, 180px -193.6666666667px #00ff1a, 92px -94.6666666667px #9d00ff, 148px -89.6666666667px #00eeff, 157px -102.6666666667px #ff00bb, 139px -267.6666666667px red, -12px -245.6666666667px #ff0051, 17px -252.6666666667px #ff2f00, 82px -63.6666666667px #c800ff, 16px -372.6666666667px #2bff00, 44px -248.6666666667px #ff00ee, -175px 56.3333333333px #62ff00, 168px -151.6666666667px #ff5900, 238px -262.6666666667px #ddff00, 20px -257.6666666667px #0099ff, -89px -170.6666666667px #6a00ff, 195px -3.6666666667px #ff005e, -247px -226.6666666667px #0033ff, -169px 7.3333333333px #f7ff00, -150px -266.6666666667px #0091ff, 76px -230.6666666667px #ff0091, 13px -284.6666666667px #ff006a, 37px -95.6666666667px #0059ff, -92px -67.6666666667px #ff00b3, -212px -244.6666666667px #0077ff, 223px -20.6666666667px #ffa600, 42px -265.6666666667px #a600ff, 175px -367.6666666667px #6fff00, -158px -85.6666666667px #5900ff, 140px -167.6666666667px #ffc800, -30px -319.6666666667px #11ff00, -119px -223.6666666667px #ff00aa, -144px -142.6666666667px #ff9500, 250px 38.3333333333px #ff0004, 214px -334.6666666667px #ff0095, 73px -367.6666666667px #00ffd9, -41px 54.3333333333px #5500ff, -89px 78.3333333333px #4800ff, -25px -167.6666666667px #ff007b, -236px 23.3333333333px #84ff00, -217px -221.6666666667px #ff0009, 65px -288.6666666667px #00ff7b;
  }
}

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-webkit-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-moz-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-o-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-ms-keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes position {

  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }

  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }

  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }

  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }

  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

.heart {
  animation: beat 1s infinite alternate;
}

/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.8);
  }
}

.slides-main-div {
  background: url('/images/luckyTable/background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100vw;
  height: 100vh;
  background-position: center;
  position: relative;
  font-family: 'Bungee-Regular';
  position: fixed;
  z-index: 99999;
}

.first-slide {
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
  margin: auto;
  transform: scale(2);
  text-align: center;
}

.second-slide {
  display: none;
  text-align: center;
  padding-top: 0;
  background: url('/images/luckyTable/bubble.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: initial;
  height: 100%;
}

.second-slide h1 {
  /* border-bottom: 1px solid gold; */
  /* border-top: 1px solid gold; */
  /* height: 75px; */
  font-size: 550px;
  /* margin-top: 10px; */
  position: absolute;
  left: 0;
  right: 0;
  margin: -130px auto;
}

img.number-img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 27%;
}

.third-slide {
  display: none;
  width: 100%;
  height: 100%;
}

.prize-title {
  display: none;
  background: url('/images/luckyTable/text_box.png');
  background-repeat: no-repeat;
  width: 1116px;
  height: 500px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  bottom: 215px;
  background-position: center;
  background-size: 100% 100%;
}

/* .prize-title h2 {
	line-height: 1.5;
	text-align: center;
	text-transform: uppercase;
	color: black;
	font-size: 65px;
	padding: 90px 120px 10px 65px;
} */

.prize-title h2 {
  line-height: 1.5;
  text-transform: uppercase;
  color: black;
  font-size: 65px;
  display: flex;
  width: calc(100% - 200px);
  height: 252px;
  overflow: hidden;
  margin: 115px auto;
  align-items: center;
  justify-content: center;
  padding-right: 35px;
}










body {
  font-family: 'Bungee-Regular';
  overflow: hidden;
}

.slider {
  position: absolute;
  width: 100%;
  height: 100%;
  margin-top: 10%;
}


.slider-main-div {
  background: url('/images/luckyTable/background.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
}

.slider-title {
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99;
  transition: all 0.5s;
}

.slider-title-animate {
  transform: scale(0.5);
}

.cangragulation-title {
  text-transform: uppercase;
  position: absolute;
  text-align: center;
  font-size: 0px;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
  color: white;
  display: none;
}

/* .wrap div {
	font-size: 60px;
	height: 30%;
	width: 100%;
	background-color: transparent;
	color: white;
	display: block;
	margin: auto;
	max-width: 100%;
	font-weight: bold;
	text-align: center;
	border: 1px solid transparent;
} */

.wrap {
  position: relative;
  top: -400px;
  width: 100%;
  height: calc(100% + 400px);
  margin: auto;
  overflow: hidden;
}

.wrap div {
  font-size: 80px;
  height: 33%;
  width: 100%;
  background-color: transparent;
  color: #ae1c5d;
  display: block;
  margin: auto;
  max-width: 100%;
  font-weight: bold;
  text-align: center;
  border: 1px solid transparent;
  font-size: 120px;
}

.wrap div h1 {
  padding-top: 120px;
  color: #ae1c5d;
}

.bg-img {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 50%;
}

.firework-div {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  top: 0;
  display: none;
}
</style>


